import React from "react";

import styled from 'styled-components'

import { useStaticQuery, graphql } from "gatsby"
import { Link } from 'gatsby'

import SEO from "../components/seo"
import Layout from "../components/layout"
import TextBlock from '../components/textBlock'
import Hero from '../components/hero'
import SectionTitle from '../components/sectionTitle'
import Button from '../components/button'
import ImageCard from '../components/imageCard'
import ImageContainer from '../components/imageContainer'

const TravelPage = () => {

    const data = useStaticQuery(graphql`
        query {
            hero: file(relativePath: { eq: "Engagement-57.jpg" }) {
                childImageSharp {
                fluid(maxWidth: 1440) {
                    ...GatsbyImageSharpFluid
                }
                }
            },
        ttd1: file(relativePath: { eq: "ny-casino.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }
            }
        }
        ttd2: file(relativePath: { eq: "maidofmist.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }
            }
        }
        ttd3: file(relativePath: { eq: "canadianfalls.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }
            }
        }
        ttd4: file(relativePath: { eq: "cliftonhill.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }
            }
        }
        dt1: file(relativePath: { eq: "dt1.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }
            }
        }
        dt2: file(relativePath: { eq: "dt2.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }
            }
        }
        dt3: file(relativePath: { eq: "dt3.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }
            }
        }
        dt4: file(relativePath: { eq: "dt4.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }
            }
        }
        dt5: file(relativePath: { eq: "dt5.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }
            }
        }
        
    }
    `)

    const HeaderContainer = styled.div({
        backgroundColor: '#fff',
        padding: '24px',
        boxSizing: 'border-box',
        width: 'calc(100% - 32px)',
        display: 'block',
        margin: 'auto',
        'box-shadow': '0px 0px 12px 4px rgba(0, 0, 0, 0.2)',
        borderRadius: '16px',
        marginTop: "-24px",
        position: 'relative',
        'z-index': '10',
    })

    const HotelImages = styled.img`
        margin-top: 16px;
        max-width: 100%;
        border-radius: 16px;
        display: block;
      `

    const TravelTitle = styled.span`
        font-family: 'Source Serif Pro', serif;
        color: #666;
        font-size: 14px;
        display: block;
        line-height: 14px;
      `

    const TravelItem = styled.span`
        font-family: 'Lato', arial, sans-serif;
        color: #323232;
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        margin-top: 8px;
        display: block;
      `

    const OtherHotelContainer = styled(Link)`
        padding: 24px;
        box-sizing: border-box;
        width: calc(100% - 32px);
        background-color: #e3e3e3;
        display: block;
        margin: auto;
        border-radius: 16px;
    
      `

    const OtherHotelTitle = styled.span`
        font-family: 'Lato', arial, sans-serif;
        color: #323232;
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 8px;
        display: block;
      `
    const OtherHotelAddress = styled.span`
        font-family: 'Source Serif Pro', serif;
        color: #666;
        font-size: 14px;
        line-height: 14px;
        display: block;
      `

    return (
        <div>
            <Layout>
                <SEO title="Travel | Reinhart Mitton" />
                <Hero file={data.hero.childImageSharp.fluid} style={{ marginTop: '50px' }} />
                <HeaderContainer>
                    <TextBlock
                        title="Travel"
                        subTitle="Double Tree by Hilton"
                    />
                    <div style={{ display: 'flex', width: 'calc(100% + 8px)', marginLeft: '-4px' }}>
                        <div style={{ margin: '0px 4px' }}>
                            <HotelImages src="https://www.hilton.com/im/en/IAGBADT/5462002/doubletree-niagarafalls-exterior-front-morning.jpg?impolicy=crop&cw=3816&ch=2826&gravity=NorthWest&xposition=505&yposition=87&rw=424&rh=314" />
                        </div>
                        <div style={{ margin: '0px 4px' }}>
                            <HotelImages src="https://www.hilton.com/im/en/IAGBADT/5461971/doubletree-nfalls-restaurantbar-morning.jpg?impolicy=crop&cw=3816&ch=2826&gravity=NorthWest&xposition=41&yposition=87&rw=424&rh=314" />
                        </div>
                        <div style={{ margin: '0px 4px' }}>
                            <HotelImages src="https://www.hilton.com/im/en/IAGBADT/1830100/doubletree-nfalls-swimmimgpool-a.jpg?impolicy=crop&cw=3816&ch=2826&gravity=NorthWest&xposition=121&yposition=87&rw=424&rh=314" />
                        </div>
                        <div style={{ margin: '0px 4px' }}>
                            <HotelImages src="https://www.hilton.com/im/en/IAGBADT/5461918/doubletree-nfalls-fitnesscenter-b.jpg?impolicy=crop&cw=3816&ch=2826&gravity=NorthWest&xposition=92&yposition=87&rw=424&rh=314" />
                        </div>
                        <div style={{ margin: '0px 4px' }}>
                            <HotelImages src="https://www.hilton.com/im/en/IAGBADT/5462013/doubletree-niagarafalls-patiodusk-below.jpg?impolicy=crop&cw=4240&ch=3140&gravity=NorthWest&xposition=184&yposition=158&rw=424&rh=314" />
                        </div>
                    </div>
                    <TravelTitle style={{ marginTop: '24px' }}>
                        Location
                    </TravelTitle>
                    <TravelItem>
                        Niagara Falls, NY
                    </TravelItem>
                    <TravelTitle style={{ marginTop: '12px' }}>
                        Date
                    </TravelTitle>
                    <TravelItem>
                        May 29, 2021
                    </TravelItem>
                    <TravelTitle style={{ marginTop: '12px' }}>
                        Group Code
                    </TravelTitle>
                    <TravelItem>
                        REI
                    </TravelItem>
                    <Button text="Book Stay" url="https://www.hilton.com/en/hotels/iagbadt-doubletree-niagara-falls-new-york/" style={{ marginTop: '24px' }} target="_blank" />
                </HeaderContainer>

                <SectionTitle title="Other Area Hotels" style={{ margin: "24px 16px", display: "block" }} />
                <OtherHotelContainer to="https://www.hilton.com/en/book/reservation/rooms/?ctyhocn=IAGNYHX&arrivalDate=2020-10-17&departureDate=2020-10-18&room1NumAdults=2&inputModule=HOTEL_SEARCH&viewPackagesAndPromotionsRate=true&WT.mc_id=zLADA0WW1XX2OLX3DA4MS5MS6_IAGNYHX7_129231037_&hmGUID=d6ed4c99-def9-486c-1199-e5a027ce3323" target="_blank">
                    <OtherHotelTitle>
                        Hampton Inn Niagara Falls
            </OtherHotelTitle>
                    <OtherHotelAddress>
                        501 Rainbow Boulevard<br />
                        Niagara Falls, NY 14303
            </OtherHotelAddress>
                </OtherHotelContainer>
                <OtherHotelContainer to="https://www.wyndhamhotels.com/wingate/niagara-falls-new-york/wingate-by-wyndham-niagara-falls/rooms-rates?hotel_id=47379&checkin_date=10/17/2020&checkout_date=10/18/2020&adults=2&children=0&rooms=1&brand_id=WG&cid=ME:1tctd8m5r13125d:47379" style={{ marginTop: '12px' }} target="_blank">
                    <OtherHotelTitle>
                        Wingate by Wyndham
            </OtherHotelTitle>
                    <OtherHotelAddress>
                        333 Rainbow Blvd<br />
                        Niagara Falls, NY 14303
            </OtherHotelAddress>
                </OtherHotelContainer>
                <OtherHotelContainer to="https://reservations.travelclick.com/15425?dateIN=10%2F17%2F2020&nights=1&adults=2&hotelID=15425&rateplanID=5040841&roomtypeID=126432&subchan=GOOGLE_US_desktop&currency=USD&utm_source=googleha&utm_medium=meta&utm_content=HPA_15425_localuniversal_1_US_desktop_2020-10-17_default_100042007_&children=0&languageid=en&gdp=hotelfinder&utm_campaign=ds_100042007" style={{ marginTop: '12px' }} target="_blank">
                    <OtherHotelTitle>
                        Seneca Niagara Resort & Casino
            </OtherHotelTitle>
                    <OtherHotelAddress>
                        310 4th St<br />
                        Niagara Falls, NY 14303
            </OtherHotelAddress>
                </OtherHotelContainer>
                <SectionTitle title="Where to Fly" style={{ margin: "24px 16px", display: "block" }} />

                <OtherHotelContainer href="https://www.buffaloairport.com/" target="_blank">
                    <OtherHotelTitle>
                        Buffalo Niagara International Airport
            </OtherHotelTitle>
                    <OtherHotelAddress>
                        4200 Genesee Street - Buffalo, NY
            </OtherHotelAddress>
                </OtherHotelContainer>
                <OtherHotelContainer href="https://www.niagarafallsairport.com/" style={{ marginTop: '12px' }} target="_blank">
                    <OtherHotelTitle>
                        Niagara Falls International Airport
            </OtherHotelTitle>
                    <OtherHotelAddress>
                        2035 Niagara Falls Blvd - Niagara Falls, NY
            </OtherHotelAddress>
                </OtherHotelContainer>

                <SectionTitle title="Things to Do" style={{ margin: "24px 16px", display: "block" }} />
                <ImageContainer>
                    <ImageCard name="Seneca Niagara Casino" image={data.ttd1.childImageSharp.fluid} />
                    <ImageCard name="Maid of the Mist" image={data.ttd2.childImageSharp.fluid} />
                </ImageContainer>
                <ImageContainer>
                    <ImageCard name="Falls View Canada" image={data.ttd3.childImageSharp.fluid} />
                    <ImageCard name="Clifton Hill Canada" image={data.ttd4.childImageSharp.fluid} />
                </ImageContainer>
                <Button text="More Things to Do" url="/things-to-do" style={{ margin: '24px auto', width: 'calc(100% - 32px)', display: 'block' }} />
            </Layout>
        </div>
    )
}

export default TravelPage